import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ArtDetails = ({ artData ,artSpecificData }) => {
  console.log("artData...",typeof artData)
  const [data , setData] = useState([])

useEffect(()=>{
if(artData){
  setData(artData)
}
},[artData])

  return (
    <Container fluid style={{ marginTop: '20px' }}>
      {data.length > 0 && data?.map((art, index) => (
        <Row key={index} className="mb-4 artdetail-row px-2">
          <Col xs={12} className='mb-2'>
            <h4>{art.art_name}</h4>
            <div className='d-flex flex-wrap'>
              <ul>
                <li><p><strong>Artist</strong>: {art.artist}</p></li>
                <li><p><strong>Year</strong>: {art.year}</p></li>
              </ul>

              <ul>
                <li> <p><strong>Medium</strong>: {art.medium}</p></li>
                <li> <p><strong>Owned by</strong>: {artSpecificData?.owner_information?.owner_type}</p></li>
              </ul>
            </div>
          </Col>
          <Col xs={12} >
            <h4>Location Details</h4>
            <div className='d-flex flex-wrap'>
              <ul className='locaton-ul'>
                  <li  className='locaton-address-li'><p><strong>Address</strong>: {art.art_address}</p></li>
                  <li  className='locaton-floor-li'><p><strong>Floor</strong>: {art.art_floor}</p></li>
                  <li  className='locaton-city-li'><p><strong>City</strong>: {art.art_city}</p></li>
              </ul>
              <ul className='location-ul'>
                  <li  className='locaton-zip-li'><p><strong>ZIP</strong>: {art.art_zip}</p></li>
                  <li  className='locaton-state-li'><p><strong>State</strong>: {art.art_state}</p></li>
                  <li  className='locaton-country-li'><p><strong>Country</strong>: {art.art_country}</p></li>
              </ul>
            </div>
            
            
            
            
            
            
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default ArtDetails;
