import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { BsChevronDown } from 'react-icons/bs';
import { FaFileAlt } from 'react-icons/fa';
import { IoIosEye } from 'react-icons/io';
import FileViewer from "react-file-viewer";

export default function VideoConfiguration({
  documentsData,
  descriptionData,
}) {
  const [showMore, setShowMore] = useState(false);
  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);

  // Filter and merge the data arrays
  useEffect(() => {
    const filteredDocuments = documentsData?.filter(doc => doc?.images?.type === "mp4") || [];
    const filteredDescriptions = descriptionData?.filter(desc => desc?.images?.type === "mp4") || [];

    const mergedArray = [...filteredDocuments, ...filteredDescriptions];
    setData(mergedArray);
  }, [documentsData, descriptionData]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  const viewDoc = (documentData) => {
    setOpenModal(true);
    setView(true);
    if (documentData?.images?.path) {
      const fileName = `${process.env.REACT_APP_IMAGE_URL}${documentData.images.path}`;
      setFile(fileName);
    }
    setType(documentData?.images?.type);
  };

  const handleClose = () => {
    setOpenModal(false);
    setView(false);
  };

  return (
    <div className="pd-sidebar-documents">
      {data && data.length > 0 ? (
        <>
          <Row className="m-0">
            {data?.map((val, ind) => {
              if (ind < 6 || showMore) {
                const subheaderText = val?.images?.original_name
                  ? val.images.original_name
                  : val.name;
                const truncatedSubheader = truncateString(subheaderText, 20);

                return (
                  <Col key={ind} xs={12} className="mb-3">
                    <div className="pd-action-box" style={{ marginTop: "20px" }}>
                      <div className="pd-action-content d-flex align-items-start">
                        <p className="mb-2 text-muted" style={{ display: "flex" }}>{truncatedSubheader}</p>
                        <Button
                          variant="primary"
                          className="square-icon-btn"
                          onClick={() => viewDoc(val)}
                          style={{ display: "flex", marginLeft: "10px" }}
                        >
                          <IoIosEye />
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              } else {
                return null;
              }
            })}
          </Row>

          {showMore === false && data?.length > 6 && (
            <Row className="mt-2">
              <Col className="text-start">
                <span>More</span>
              </Col>
              <Col className="text-end">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleShowMore}
                >
                  <BsChevronDown />
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <p className="text-center">No documents available to view</p>
      )}

      {view && (
        <Modal
          className="projectDocumentsModal"
          show={openModal}
          onHide={handleClose}
          size="lg"
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Body className='kyc-docs'>
            <FileViewer fileType={type} filePath={file} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
