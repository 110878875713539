import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { postApi } from '../../Api/api';
import FileViewer from 'react-file-viewer';
import { IoIosEye } from 'react-icons/io';
import { toast } from 'react-hot-toast';

const SignedDocumentTemplate = ({ documentsData, assetId }) => {
  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async (data) => {
    setLoading(true);

    try {
      const payload = {
        asset_id: assetId,
        document_id: data?.images.id
      };
      const res = await postApi("/docusign/createTemplate", payload);
      if (res?.data?.code === 200) {
        const popup = window.open(res?.data?.data?.url);
        // Check if the popup was blocked
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          toast('Popup blocked or not opened.', {
            duration: 2000,
            position: 'top-right',
            type: 'warning'
          })
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);

    }
  };

  const viewDoc = (docData) => {
    setOpenModal(true);
    setView(true);
    if (docData?.images.path) {
      const fileName = `${process.env.REACT_APP_IMAGE_URL}${docData?.images?.path ? docData.images.path : ""}`;
      setFile(fileName);
    }
    if (docData?.images.type === "document" || docData?.images.type === "doc") {
      setType("pdf");
    } else {
      setType(docData?.images.type);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setView(false);
  };

  return (
    <>
      <div style={{ margin: '20px' }}>
        {documentsData?.map((data) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "8px" }} key={data?.images?.id}>
            <div>
              <span>{data?.images.original_name}</span>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Button
                variant="primary"
                className="square-icon-btn"
                onClick={() => viewDoc(data)}
                // style={{ display: "flex", marginLeft: "10px" }}
              >
                <IoIosEye />
              </Button>
              <Button
                onClick={() => handleButtonClick(data)}
                variant="success"
                style={{ marginRight: '8px' }}
                disabled={loading}
              >
                {loading ? "Update Template" : data.template_status ? "Update Template" : "Create  Template"}
              </Button>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <Spinner animation="border" variant="light" />
        </div>
      )}
      {view && (
        <Modal
          className="projectDocumentsModal"
          show={openModal}
          onHide={handleClose}
          size="lg"
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Body className='kyc-docs'>
            <FileViewer fileType={type} filePath={file} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SignedDocumentTemplate;
