import { lazy, useState, useEffect, useCallback, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Session from "./Session";
import Loader from "./Loader";
import ProjectDetail from "../components/ProjectManagement/ProjectDetail";
import { useAuth } from "../Auth/Login/AuthContext";

/**
 * lazy loader components
 */
const Login = lazy(() => import("../Auth/Login/login"));
const RangeConfig = lazy(() => import("../components/Master/RangeConfigMaster/RangeConfig"));
const RepaymentRangeConfigMaster = lazy(() => import("../components/Master/RangeConfigMaster/RepaymentConfiguration/RepaymentRangeConfigMaster"))
const DicConfig = lazy(() => import("../components/Master/DicConfigMaster/dicConfig"));
const DicConfigList = lazy(() => import("../components/Master/DicConfigMaster/DicConfigList"));
const TransectionList = lazy(() => import("../components/TransectionManagement/TransectionList"));
const TransectionDetails = lazy(() => import("../components/TransectionManagement/TransectionDetails"));
const Sidebars = lazy(() => import("../components/Common/Sidebar/Sidebar"));
const Header = lazy(() => import("../components/Common/Header/Header"));
const ProjectListPage = lazy(() =>
  import("../components/ProjectManagement/Projects")
);
const Dashboard = lazy(() => import("../components/Dashboard/Dashboard"));
const AppUserPage = lazy(() => import("../components/UserManagement/AppUser"));
const RolesList = lazy(() => import("../components/RolesAndPermission/Roles"));
const AddRolesPermission = lazy(() =>
  import("../components/RolesAndPermission/Permission")
);
const SystemUserPage = lazy(() =>
  import("../components/SystemUserManagement/SystemUser")
);
const AddUser = lazy(() =>
  import("../components/SystemUserManagement/AddUser")
);
const UserDetail = lazy(() =>
  import("../components/UserManagement/UserDetail")
);

const CreateBlog = lazy(() =>
  import("../components/BlogManagement/CreateBlog")
);

const BlogList = lazy(() => import("../components/BlogManagement/BlogList"));
const NotFound = lazy(() => import("../components/Pages/404page"));
const Faq = lazy(() => import("../components/Pages/Faq"));
const MemberRequestPage = lazy(() =>
  import("../components/MemberRequest/MemberRequest")
);
const DicMember = lazy(() => import("../components/DicMember/DicMember"));
const TermsCondition = lazy(() =>
  import("../components/CMSContent/TermsCondition")
);
const TermsConditionFoundation = lazy(() =>
  import("../components/CMSContent/TermsConditionFoundation")
);
const Privacypolicy = lazy(() =>
  import("../components/CMSContent/PrivacyPolicy")
);
const PrivacypolicyFoundation = lazy(() =>
  import("../components/CMSContent/PrivacyPolicyFoundation")
);
const Profession = lazy(() => import("../components/AddProfession/Profession"));
const AssetReviewList = lazy(() =>
  import("../components/Master/AssetReviewMaster/List")
);
const PaymentTerm = lazy(() => import("../components/PaymentTerms/PaymentTermMain"));
const AssetReviewConfiguration = lazy(() =>
  import("../components/Master/AssetReviewMaster/Configuration")
);

/**
 * Routes for the Member Request
 */
const Investor = lazy(() => import("../components/MemberRequest/Investor"))
const Proposer = lazy(() => import("../components/MemberRequest/Proposer"))
const ServiceProvider = lazy(() => import("../components/MemberRequest/ServiceProvider"))
const InvestmentCommitteeMember = lazy(() => import("../components/MemberRequest/InvestmentCommitteeMember"))
const LiquidityProvider = lazy(() => import("../components/MemberRequest/LiquidityProvider"))
const ActivityLogs = lazy(() => import("../components/ActivityLog/ActivityLogs"))
const DicMemberReviewPage = lazy(() => import("../components/DicMember/DicMemberReviewPage"))
const InvestmentCommitieDeailPage = lazy(() => import("../components/MemberRequest/InvestmentCommitieDetails"))
const Broker = lazy(() => import("../components/MemberRequest/Broker"))
const AssetCustodyProvider = lazy(() => import("../components/MemberRequest/AssetCustodyProvider"))
const AssetCustodyProviderDetails = lazy(() => import("../components/MemberRequest/AssstCustodyProviderDetails"))
const LawFirm = lazy(() => import("../components/MemberRequest/LawFirm"))
const DecentralizeFinancialAdvisor = lazy(() => import("../components/MemberRequest/DecentralizedFinancialAdvisor"))
const OthersRole = lazy(() => import("../components/MemberRequest/OthersRole"))
const OtherRoleDetailPage = lazy(() => import("../components/MemberRequest/OtherRoleDetailPage"))
const EmailSubscriptions = lazy(() => import("../components/MemberRequest/EmailSubscriptions"))
const TechDeveloper = lazy(() => import("../components/MemberRequest/TechDeveloper"))
const Serviceproviderconfig = lazy(() => import("../components/ServiceProvider/ServiceProviderConfiguration"))
const ServiceProviderSkill = lazy(() => import("../components/ServiceProvider/ServicePorviderSkill"))
const ServiceProviderSevices = lazy(() => import("../components/ServiceProvider/ServiceProviderServices"))
const Language = lazy(() => import("../components/ServiceProvider/Language"))
const SubCategory = lazy(() => import("../components/ServiceProvider/SubCategory"))
const LandingPageConfiguration = lazy(() => import("../components/LandingPageConfiguration/LandingPageConfiguration"))
const ProjectRepaymentConfiguration = lazy(() => import("../components/ProjectManagement/ProjectRepaymentConfiguration"))
const PaymentConfiguration = lazy(() => import("../components/PaymentConfiguration/PaymentConfiguration"))
const AuctionConfiguration = lazy(() => import("../components/Master/AuctionConfiguration/AuctionConfiguration"))
const ProjectCloserConfiguration = lazy(() => import("../components/Master/ProjectCloserConfiguration/projectCloserConfiguration"))
const HowItWorksConfiguration = lazy (()=>import("../components/Master/HowItWorks/HowItWorksMaster"))
const InvestmentTerms = lazy(()=> import("../components/Master/InvestmentTerms/InvestmentTermsMain"))


function AppRouter() {
  // State for managing sidebar collapse/expand and user login
  const [collapsed, setCollapsed] = useState(false);
  const [isloggedIn, setIsloggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("admintoken"));
  const { rememberMe, toggleRememberMe, isLoggedIn } = useAuth();

  // Retrieve the user's token from local storage
  useEffect(() => {
    const storedToken = localStorage.getItem("admintoken");
    if (storedToken) {
      setToken(storedToken);
      setIsloggedIn(true);
    } else {
      setToken(null);
      setIsloggedIn(false);
    }

    const handleStorageChange = () => {
      const updatedToken = localStorage.getItem("admintoken");
      setToken(updatedToken);
      setIsloggedIn(!!updatedToken);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  /**
   * Function to expand/collapse the sidebar
   */
  const expandSidbar = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  /**
   * Function to handle user login
   */
  const handleLogin = useCallback(() => {
    setIsloggedIn(true);
    setToken(localStorage.getItem("admintoken"));
  }, []);


  return (
    <Router>
      {/* Toast notifications */}
      <Toaster />
      {/* Session management */}
      <Session />
      {/* Check if the user is logged in */}
      {token ? (
        <>
          {/* Header component */}
          <Header
            expandSidbar={expandSidbar}
            setIsloggedIn={setIsloggedIn}
            collapsed={collapsed}
          />
          {/* Main container */}
          <div className="main_container">
            {/* Sidebar component */}
            <Sidebars collapsed={collapsed} setIsloggedIn={setIsloggedIn} />

            <div className="main_content">
              <div className="mainWrapper">
                {/* Lazy-loaded routes */}
                <Suspense fallback={<Loader />}>
                  <Routes>

                    {/* Redirect to Dashboar when logged in */}
                    <Route path="/" element={<Dashboard />} />
                    <Route
                      exact
                      path="/appUser"
                      element={<AppUserPage />}
                    ></Route>
                    <Route
                      exact
                      path="/dashboard"
                      element={<Dashboard />}
                    ></Route>
                    <Route
                      exact
                      path="/rolesList"
                      element={<RolesList />}
                    ></Route>
                    {/* ROutes for Member Requests start */}
                    <Route
                      exact
                      path="/memberRequest"
                      element={<MemberRequestPage />}
                    ></Route>
                    <Route
                      exact
                      path="/investorinfo"
                      element={<Investor />}
                    ></Route>
                    <Route
                      exact
                      path="/proposerinfo"
                      element={<Proposer />}
                    ></Route>
                    <Route
                      exact
                      path="/investorcommitteememberinfo"
                      element={<InvestmentCommitteeMember />}
                    ></Route>
                    <Route
                      exact
                      path="/liquidityproviderinfo"
                      element={<LiquidityProvider />}
                    ></Route>
                    <Route
                      exact
                      path="/serviceproviderinfo"
                      element={<ServiceProvider />}
                    ></Route>
                    <Route
                      exact
                      path="/broker"
                      element={<Broker />}
                    ></Route>
                    <Route
                      exact
                      path="/lawfirm"
                      element={<LawFirm />}
                    ></Route>
                    <Route
                      exact
                      path="/assetcustodyprovider"
                      element={<AssetCustodyProvider />}
                    ></Route>
                    <Route
                      exact
                      path="/decentralizefinancialadvisor"
                      element={<DecentralizeFinancialAdvisor />}
                    ></Route>
                    <Route
                      exact
                      path="/assetcustodyproviderdetails"
                      element={<AssetCustodyProviderDetails />}
                    ></Route>
                    <Route
                      exact
                      path="/others"
                      element={<OthersRole />}
                    ></Route>
                    <Route
                      exact
                      path="/techdeveloper"
                      element={<TechDeveloper />}
                    ></Route>
                    <Route
                      exact
                      path="/otherdetails"
                      element={<OtherRoleDetailPage />}
                    ></Route>
                    <Route
                      exact
                      path="/emailsubscribe"
                      element={<EmailSubscriptions />}
                    ></Route>
                    <Route
                      exact
                      path="/memberRequest"
                      element={<MemberRequestPage />}
                    ></Route>
                    {/* ROutes for Member Requests Ends */}
                    <Route
                      exact
                      path="/addRolesPermission"
                      element={<AddRolesPermission />}
                    ></Route>
                    <Route
                      exact
                      path="/blogManage"
                      element={<BlogList />}
                    ></Route>
                    <Route
                      exact
                      path="/createBlog"
                      element={<CreateBlog />}
                    ></Route>
                    <Route
                      exact
                      path="/projects"
                      element={<ProjectListPage />}
                    ></Route>
                    <Route
                      exact
                      path="/projectDetail"
                      element={<ProjectDetail />}
                    ></Route>
                    <Route
                      exact
                      path="/systemUser"
                      element={<SystemUserPage />}
                    ></Route>
                    <Route
                      exact
                      path="/addUser"
                      element={<AddUser />}
                    ></Route>
                    <Route
                      exact
                      path="/userDetail"
                      element={<UserDetail />}
                    ></Route>
                    <Route
                      exact
                      path="/RangeConfig"
                      element={<RangeConfig />}
                    ></Route>
                    <Route
                      exact
                      path="/HowItWorks"
                      element={<HowItWorksConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/investmentterms"
                      element={<InvestmentTerms />}
                    ></Route>
                    <Route
                      exact
                      path="/repaymentrangeconfig"
                      element={<RepaymentRangeConfigMaster />}
                    ></Route>
                    <Route
                      exact
                      path="/dicConfigList"
                      element={<DicConfigList />}
                    ></Route>
                    <Route
                      exact
                      path="/faq"
                      element={<Faq />}
                    ></Route>
                    <Route
                      exact
                      path="/dicMember"
                      element={<DicMember />}
                    ></Route>
                    {/* New Routes For the Asset checklist master */}
                    <Route
                      exact
                      path="/assetreviewlist"
                      element={<AssetReviewList />}
                    ></Route>
                    <Route
                      exact
                      path="/assetreviewconfiguration"
                      element={<AssetReviewConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/termscondition"
                      element={<TermsCondition />}
                    ></Route>
                    <Route
                      exact
                      path="/termsconditionfoundation"
                      element={<TermsConditionFoundation />}
                    ></Route>
                    <Route
                      exact
                      path="/privacypolicy"
                      element={<Privacypolicy />}
                    ></Route>
                    <Route
                      exact
                      path="/privacypolicyfoundation"
                      element={<PrivacypolicyFoundation />}
                    ></Route>
                    <Route
                      exact
                      path="/profession"
                      element={<Profession />}
                    ></Route>
                    <Route
                      exact
                      path="/dicConfig"
                      element={<DicConfig />}
                    ></Route>
                    <Route
                      exact
                      path="/reviewpage"
                      element={<DicMemberReviewPage />}
                    ></Route>
                    <Route
                      exact
                      path="/investmentcommitieedetails"
                      element={<InvestmentCommitieDeailPage />}
                    ></Route>
                    <Route
                      exact
                      path="/activitylog"
                      element={<ActivityLogs />}
                    ></Route>
                    {/* Service provider Configuration start */}
                    <Route
                      exact
                      path="/serviceproviderconfig"
                      element={<Serviceproviderconfig />}
                    ></Route>
                    {/* Service provider Configuration ends */}
                    {/* Service provider Skill start */}
                    <Route
                      exact
                      path="/serviceproviderskill"
                      element={<ServiceProviderSkill />}
                    ></Route>
                    <Route
                      exact
                      path="/serviceproviderservices"
                      element={<ServiceProviderSevices />}
                    ></Route>
                    {/* Service provider Skill ends */}
                    <Route
                      exact
                      path="/language"
                      element={<Language />}
                    ></Route>
                    <Route
                      exact
                      path="/subcategory"
                      element={<SubCategory />}
                    ></Route>
                    <Route
                      exact
                      path="/langingpageConfiguration"
                      element={<LandingPageConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/transections"
                      element={<TransectionList />}
                    ></Route>
                    <Route
                      exact
                      path="/transectiondetails"
                      element={<TransectionDetails />}
                    ></Route>
                    <Route
                      exact
                      path="/projectrepaymentconfiguration"
                      element={<ProjectRepaymentConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/paymentconfiguration"
                      element={<PaymentConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/auctionconfiguration"
                      element={<AuctionConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/projectcloserconfiguration"
                      element={<ProjectCloserConfiguration />}
                    ></Route>
                    <Route
                      exact
                      path="/paymenttermconfiguration"
                      element={<PaymentTerm />}
                    ></Route>
                    <Route
                      exact
                      path="*"
                      element={<NotFound />}
                    ></Route>
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
        </>
      ) : (
        // Routes for when the user is not logged in
        <Routes>
          <Route path="/" element={<Login handleLogin={handleLogin} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Router>
  );
}

export default AppRouter;
