export const ASSIGNED_PROJECT_STATUS = {
    NOT_REVIEWED: 0,
    REVIEWED: 1,
    APPROVED: 2,
    REJECTED: 3,
    FUNDED: 4,
    INPROGRESS: 5
}

/**
 *  SHUFFTI PRO Kyc events , also used to know status of a kyc request.
 * @readonly 
 * @enum {string}
 */
export const SHUFTI_KYC_EVENTS = {
    REQUEST_PENDING: "request.pending",
    REQUEST_INVALID: "request.invalid",
    REQUEST_TIMEOUT: "request.timeout",
    REQUEST_UNAUTHORIZED: "request.unauthorized",
    REQUEST_DELETED: "request.deleted",
    REQUEST_RECEIVED: "request.received",
    VERIFICATION_CANCELLED: "verification.cancelled",
    VERIFICATION_ACCEPTED: "verification.accepted",
    VERIFICATION_DECLINED: "verification.declined",
    VERIFICATION_STATUS_CHANGED: "verification.status.changed",
    REVIEW_PENDING: "review.pending",
    NOT_INITIATED: "not.initiated", //custom event for not initiated
};

/**
 * Human-readable display names for SHUFTI_KYC_EVENTS.
 * @readonly
 * @enum {string}
 */
export const SHUFTI_KYC_EVENTS_DISPLAY = {
    [SHUFTI_KYC_EVENTS.REQUEST_PENDING]: "Request Pending",
    [SHUFTI_KYC_EVENTS.REQUEST_INVALID]: "Request Invalid",
    [SHUFTI_KYC_EVENTS.REQUEST_TIMEOUT]: "Request Timeout",
    [SHUFTI_KYC_EVENTS.REQUEST_UNAUTHORIZED]: "Request Unauthorized",
    [SHUFTI_KYC_EVENTS.REQUEST_DELETED]: "Request Deleted",
    [SHUFTI_KYC_EVENTS.REQUEST_RECEIVED]: "Request Received",
    [SHUFTI_KYC_EVENTS.VERIFICATION_CANCELLED]: "Verification Cancelled",
    [SHUFTI_KYC_EVENTS.VERIFICATION_ACCEPTED]: "Verification Accepted",
    [SHUFTI_KYC_EVENTS.VERIFICATION_DECLINED]: "Verification Declined",
    [SHUFTI_KYC_EVENTS.VERIFICATION_STATUS_CHANGED]: "Verification Status Changed",
    [SHUFTI_KYC_EVENTS.REVIEW_PENDING]: "Review Pending",
    [SHUFTI_KYC_EVENTS.NOT_INITIATED]: "Not Initiated",
};

/**
 * Colors for respective SHUFTI_KYC_EVENTS.
 * @readonly
 * @enum {string}
 */
export const SHUFTI_KYC_EVENTS_COLORS = {
    [SHUFTI_KYC_EVENTS.REQUEST_PENDING]: "blue",
    [SHUFTI_KYC_EVENTS.REQUEST_INVALID]: "red",
    [SHUFTI_KYC_EVENTS.REQUEST_TIMEOUT]: "orange",
    [SHUFTI_KYC_EVENTS.REQUEST_UNAUTHORIZED]: "yellow",
    [SHUFTI_KYC_EVENTS.REQUEST_DELETED]: "gray",
    [SHUFTI_KYC_EVENTS.REQUEST_RECEIVED]: "green",
    [SHUFTI_KYC_EVENTS.VERIFICATION_CANCELLED]: "purple",
    [SHUFTI_KYC_EVENTS.VERIFICATION_ACCEPTED]: "green",
    [SHUFTI_KYC_EVENTS.VERIFICATION_DECLINED]: "red",
    [SHUFTI_KYC_EVENTS.VERIFICATION_STATUS_CHANGED]: "blue",
    [SHUFTI_KYC_EVENTS.REVIEW_PENDING]: "yellow",
    [SHUFTI_KYC_EVENTS.NOT_INITIATED]: "gray",
};

/**
 * Permission Role Module
 */
export const PERMISSION_MODULE = {
    USER_MANAGEMENT: 1,
    BLOG_MANAGEMENT: 2,
    MASTER_MANAGEMENT: 3,
    ROLES_MANAGEMENT: 4,
    CMS_CONTENT_MANAGEMENT: 5,
    ASSET_REVIEW_MASTER: 6,
    MEMBER_REQUEST: 7,
    ACTIVITY_LOG:8
}

/**
 * Info about the userId 
 */

export const USER_ID = {
    USER_ID_ADMIN : localStorage.getItem("adminId")
}

/**
 * Category type id
 */
export const CATEGORY_ID = {
    REAL_ESTATE : 1,
    GOLD:7,
    FUND:9,
    ART:2,
    WATCH:8
}

/**
 * Project Status for My project section Proposer
 */
export const STATUS_OF_PROJECT = {
    DRAFT: 1,
    SUBMITTED: 2,
    INREVIEW:3,
    REVIEWED: 4,
    PROJECT_LIVE:5,
    REJECTED: 6
}